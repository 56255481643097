<template>
    <ProfileComponent :visible="true" />
</template>

<script>
import ProfileComponent from '../../components/profile/ProfileComponent.vue';

export default {
  name: 'Profile',
  components: {
    ProfileComponent,
  },
};
</script>

<style scoped>

</style>
