<template>
  <v-col cols="12" class="text-center">
    <v-row v-if="!user.image && !photoPreview">
      <v-col cols="12">
        <VIcon size="70">mdi-account-circle</VIcon>
      </v-col>
      <v-col cols="12">
        <label for="customer_photo">
          <VBtn color="success" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
        </label>
      </v-col>
    </v-row>

    <v-row v-else-if="user.image && !photoPreview">
      <v-col cols="12">
        <img :src="user.image" alt="" width="80" class="img-preview" />
      </v-col>
      <v-col cols="12">
        <label for="customer_photo">
          <VBtn color="success" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
        </label>
      </v-col>
    </v-row>
    <VFileInput
      id="customer_photo"
      class="d-none"
      v-model="user.photo"
      accept="image/jpg, image/jpeg, application/pdf"
      @change="makePreview"
    />
    <v-row v-if="photoPreview" align="center" justify="center">
      <v-col cols="12">
        <img :src="photoPreview" alt="" width="80" class="img-preview" />
      </v-col>
      <v-col cols="12" v-if="!pre">
        <VBtn @click="onChangeImage" depressed>
          {{ $t("btn.delete") }}
        </VBtn>
      </v-col>
      <v-col cols="6" v-if="pre">
        <VBtn color="success" @click="getFile">{{ $t("profile.changeBtn") }}</VBtn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "../../events/EventBus";
import * as actionTypes from "../../store/modules/auth/types/actions";
import fileSizeValidation from "@/validators/fileSizeAvatar";
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";

export default {
  name: "ProfileAvatar",
  mixins: [validationMixin, notifications],
  data: () => ({
    photoPreview: false,
    pre: false
  }),
  validations: {
    user: {
      photo: { fileSizeValidation },
    }
  },
  props: {
    user: {
      require: true,
      default: {}
    },
    changePhotoPreview: {
      require: false
    },
    resetPhoto:{
      require: false
    }
  },
  watch: {
    changePhotoPreview: {
      deep: true,
      handler() {
        this.pre = true;
      }
    },
    resetPhoto: {
      deep: true,
      handler() {
       this.onResetImage()
      }
    }
  },
  methods: {
    onResetImage(){
      this.photoPreview = false
      this.pre = false

    },
    onChangeImage() {
      delete this.user.photo
      this.photoPreview = null;
      EventBus.$emit("on-change-image", this.user);
    },
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    ...mapActions("auth", {
      updateUserData: actionTypes.USER_UPDATE_DATA
    }),

    makePreview() {
        if(this.$v.user.photo.fileSizeValidation){
          let photoPreview = null;
          const { photo } = this.user;
          const reader = new FileReader();
          reader.readAsDataURL(photo);
          reader.onloadend = ev => {
            photoPreview = ev.target.result;
            this.photoPreview = photoPreview;
            this.pre = false;
          };
        } else {
          delete this.user.photo
          this.setErrorNotification(this.$t("form.errors.photoMaxSizeAvatar"));
        }
    }
  }
};
</script>

<style scoped>
.img-preview {
  border-radius: 15%;
  border: 1px solid lightgray;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
