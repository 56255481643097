<template>
  <v-card class=" " tile>
    <v-list dense>
      <v-list-item-group
        v-model="selectedMenu"
        mandatory
        active-class="active-green--text text--accent-4"
      >
        <template v-for="(item, index) in menu">
          <v-list-item :key="item.text" :value="item.text" v-if="permissions[item.permission]">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t(`theme.${item.text}`)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset v-if="index < menu.length - 1" :key="`${index}-divider`"></v-divider>
        </template>
        <!-- <v-list-item>
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Come Back Alive</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import EventBus from "@/events/EventBus";
import user from "@/mixins/user";
// eslint-disable-next-line 
import { MENU } from "@/mixins/constants";

export function getMenuText() {
  return MENU.map(e => e.text);
}

export default {
  mixins: [user],
  mounted() {
    this.selectedMenu = this.menu.find(item => item.text === this.defaultMenu);
  },
  watch: {
    selectedMenu(e) {
      EventBus.$emit("on-change-selected-menu", this.selectedMenu);
    }
  },
  data: () => ({
    selectedMenu: null,
    defaultMenu: "user",
    menu: MENU
  })
};
</script>

<style></style>
