<template>
  <VDialog
    content-class="cross__dialog"
    v-model="visibility"
    max-width="700px"
    @click:outside="checkClosing"
    :persistent="visibility"
    :transition="$dialogTransition"
  >
    <VCard>
      <div class="cross__inner">
        <VBtn
          fab
          small
          elevation="0"
          @click="checkClosing()"
        >
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VRow class="ma-0 align-center justify-space-between npDialog__header">
        <VCol cols="12" md="6" class=" npDialog__header-title">
          {{ `${typeDialog === 'add' ?  this.$t('profile.creating') : this.$t('profile.editing')}` }}
        </VCol>
      </VRow>
      <VCardText>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="9" class="pt-0">
              <v-combobox
                dense
                outlined
                label="NP Api key"
                :item-text="'np_apikey'"
                :menu-props="{ bottom: true, offsetY: true }"
                :item-value="'np_apikey'"
                :no-data-text="$t('table.noData')"
                v-model="delivery.np_apikey"
                @change="counterparty = null; counterpartyContacts = null; counterpartyAddress = null; getDataCounterparty()"
                :hide-details="!npApiKeyErrors.length"
                
                :error-messages="npApiKeyErrors"
                :items="np_apikeys"
                color="success">
              </v-combobox>
            </VCol>
            <VCol cols="3" class="pt-0">
              <v-checkbox :label="$t('profile.monitoring_returns')" :true-value="'1'" :false-value="'0'" hide-details v-model="delivery.is_monitor_returns" color="success">
              </v-checkbox>
            </VCol>
            <VCol cols="12">
              <VTextField dense :label="$t('form.name')" outlined
                          v-model="delivery.name"
                          :hide-details="!nameErrors.length"
                          :error-messages="nameErrors"
                          color="success"
              />
            </VCol>
            <VCol cols="12">
              <VSelect dense :label="$t('form.counterparty')" outlined
                       :items="counterpartyData"
                       v-model="counterparty"
                       :item-text="counterpartyData[0]?.CounterpartyFullName ? 'CounterpartyFullName' :'FirstName'"
                       @change="counterpartyContacts = null; counterpartyAddress = null; getDataCounterpartyContacts()"
                       :hide-details="!counterpartyErrors.length"
                       :error-messages="counterpartyErrors"
                       item-value="Ref"
                       :no-data-text="$t('table.noData')"
                       color="success"
                       item-color="success"
              />
            </VCol>
            <VCol cols="12">
              <VSelect dense :label="$t('form.counterparty_sender')" outlined
                       :items="counterpartyContactsData"
                       v-model="counterpartyContacts"
                       @change="counterpartyAddress = null; getAddressContactPerson()"
                       :hide-details="!counterpartyContactsErrors.length"
                       :error-messages="counterpartyContactsErrors"
                       item-text="FirstName"
                       :no-data-text="$t('table.noData')"
                       item-value="Ref"
                       color="success"
                       item-color="success"
              />
            </VCol>
            <!-- <VCol>
              <VAutocomplete
                :items="activeCities"
                :loading="regionLoading"
                outlined
                dense
                class="br-10"
                :label="$t('form.city_receiver')"
                item-text="Description"
                item-value="Ref"
                @change="onSelectCity"
                v-model="delivery.city"
                :hide-details="!cityErrors.length"
                :error-messages="cityErrors"
              />
            </VCol>
            <VCol>
              <VAutocomplete
                :items="warehouses"
                outlined
                dense
                class="br-10"
                :label="$t('form.department')"
                item-text="Description"
                item-value="Ref"
                v-model="delivery.warehouse"
                :hide-details="!warehouseErrors.length"
                :error-messages="warehouseErrors"
                :disabled="!delivery.city"
              />
            </VCol> -->
            <VCol>
              <VSelect dense
                :label="$t('addresses.adressesTitle')"
                outlined
                :items="addresses"
                v-model="counterpartyAddress"
                item-value="Ref"
                :hide-details="!counterpartyAddressErrors.length"
                :error-messages="counterpartyAddressErrors"
                :menu-props="{ bottom: true, offsetY: true }"
                :no-data-text="$t('table.noData')"
                color="success"
                item-color="success"
              >
              <template v-slot:item="{ item }">
                {{item.CityDescription}}, {{item.AddressDescription}}
              </template>
              <template v-slot:selection="{ item }">
                {{item.CityDescription}}, {{item.AddressDescription}}
              </template>
              </VSelect>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VAutocomplete
                :items="PayerTypes"
                outlined
                dense
                :label="$t('form.PayerType')"
                v-model="delivery.PayerType"
                hide-details
                color="success"
                item-color="success"
              >
                <template v-slot:item="{ item }">
                {{ $t(`form.${item}`) }}
               </template>
                <template v-slot:selection="{ item }">
                {{ $t(`form.${item}`) }}
               </template>
            </VAutocomplete>
            </VCol>
            <VCol>
              <VAutocomplete
                :items="PaymentMethod"
                outlined
                dense
                :label="$t('form.PaymentMethod')"
                v-model="delivery.PaymentMethod"
                hide-details
                color="success"
                item-color="success"
              >
               <template v-slot:item="{ item }">
                {{ $t(`form.${item}`) }}
              </template>
              <template v-slot:selection="{ item }">
                {{ $t(`form.${item}`) }}
               </template>
            </VAutocomplete>
            </VCol>
            <VCol v-if="delivery.OwnershipFormDescription === 'ФОП' ">
              <v-checkbox
                v-model="delivery.AfterpaymentOnGoodsCost"
                hide-details
                label="Післяоплата вартості товару"
                color="success"
              ></v-checkbox>
            </VCol>
            <VCol cols="12">
              <VAutocomplete
                :items="cargoList"
                outlined
                dense
                :label="$t('profile.shipDesc')"
                return-object
                :item-text="'Description'"
                hide-no-data
                no-filter
                @change="checkCargo"
                v-on:keypress="validationForDepartment($event)"
                @paste="validationForDepartment($event)"
                :search-input.sync="searchCargoDescQuery"
                v-model="delivery.CargoDescription"
                color="success"
                item-color="success"
              >
                <template v-slot:append-item>
                  <div
                    v-if="!loadMoreCargoDisabled"
                    v-observer="{ nextPage: nextPageCargo }"
                  />
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="w-100 br-10 text-transform-none"
                dark
                @click="visibility = false"
              >
                {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-if="typeDialog === 'active'"
              >
                {{$t('btn.update')}}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-if="typeDialog !== 'add'"
                @click="addSender"
                :disabled="isChanged"
              >
                {{  $t('btn.update') }}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-if="typeDialog === 'add'"
                @click="addSender"
              >
                {{ `${typeDialog === 'add' ?   $t('btn.create') :   $t('btn.update') }` }}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="confirmLeftDialogVisible"
      @close="confirmLeftDialogVisible = false"
      @delete="$emit('close')"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import user from '../../mixins/user';
import notifications from '../../mixins/notifications';
import loader from '../../mixins/loader';
import language from '../../mixins/language';
import novaPochtaService from '../../services/request/novaPochta/novaPochtaService';
import novaPochta from '../../mixins/novaPochta';
import EventBus from '../../events/EventBus';
import _ from 'lodash'
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import {
  validationForDepartment
} from "@/mixins/helpers";

export default {
  mixins: [notifications, loader, validationMixin, user, language, novaPochta],
  components: {
    ConfirmLeftDialog,
  },
  props: {
    typeDialog: {
      type: String,
    },
    visible: {
      required: true,
      type: Boolean,
    },
    data: {
      type: Object,
    },
    np_apikeys: {
      required: true,
    },
    phone: {
      required: true,
    }
  },
  data: () => ({
    counterpartyData: [],
    counterparty: null,
    copyCounterparty: null,
    counterpartyContactsData: [],
    PayerTypes:["Sender", "Recipient", "ThirdPerson"],
    PaymentMethod:["Cash","NonCash"],
    addresses: [],
    confirmLeftDialogVisible: false,
    counterpartyContacts: null,
    copyCounterpartyContacts: null,
    counterpartyAddress: null,
    copyCounterpartyAddress: null,
    delivery: {
      uuid: '',
      name: '',
      PayerType:"Sender",
      PaymentMethod:"Cash",
      AfterpaymentOnGoodsCost:false,
      OwnershipFormDescription:"",
      np_apikey: '',
      CargoDescription: {},
      is_monitor_returns: false,
    },
    copyDelivery: {
      uuid: "",
      name: "",
      PayerType: "Sender",
      PaymentMethod: "Cash",
      AfterpaymentOnGoodsCost: false,
      OwnershipFormDescription: "",
      np_apikey: "",
      CargoDescription: {},
      is_monitor_returns: false,
    },
    cargoList: [],
    searchCargoDescQuery: "",
    loadMoreCargoDisabled: false,
    pagination: {
      page: 1,
      perPage: 50,
    },
  }),
  watch: {
    searchCargoDescQuery(val) {
      if (val || val == "") {
        this.pagination.page = 1;
        this.getCargoList(false);
      }
    },
  },
  async mounted() {
    if (this.typeDialog != 'add') {
      this.getDataCounterparty()
      this.getDataCounterpartyContacts()
      this.getAddressContactPerson()
    }

    // try {
    //   this.fetchNovaPochtaCities();
    //   this.activeCities = this.cities;
    // } catch (e) {
    //   console.log(e)
    // }
  },
  methods: {
    validationForDepartment,
    nextPageCargo() {
      this.pagination.page += 1;
      this.getCargoList(true);
    },
    getCargoList: _.debounce(async function (nextPage = false) {
      try {
        const params = {
          FindByString: this.searchCargoDescQuery,
          Page: this.pagination.page,
        };

        const res = await novaPochtaService.getCargoList(params);

        if (nextPage) {
          this.cargoList.push(...res);
        } else {
          this.cargoList = res;
        }
        this.loadMoreCargoDisabled = res.length < this.pagination.perPage;
      } catch (e) {
        console.log(e)
      }
    }, 400),
    async getDataCounterparty() {
      try {
        this.loadingCreateTTN = true;

        const formData = {
          apiKey: this.delivery.np_apikey,
          modelName: 'Counterparty',
          calledMethod: 'getCounterparties',
          methodProperties: {
            CounterpartyProperty: 'Sender',
            Page: '1',
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.counterpartyData = response.data.data;
        this.delivery.OwnershipFormDescription = this.counterpartyData[0].OwnershipFormDescription

        // orderDialog('this.counterpartyData', this.counterpartyData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    async getDataCounterpartyContacts() {
      try {
        this.loadingCreateTTN = true;
        const formData = {
          apiKey: this.delivery.np_apikey,
          modelName: 'Counterparty',
          calledMethod: 'getCounterpartyContactPersons',
          methodProperties: {
            Ref: this.counterparty,
            Page: '1',
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.counterpartyContactsData = response.data.data;
        // orderDialog('this.counterpartyContactsData', this.counterpartyContactsData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    async getAddressContactPerson() {
      try {
        this.loadingCreateTTN = true;
        const formData = {
          apiKey: this.delivery.np_apikey,
          modelName: "ContactPersonGeneral",
          calledMethod: "getContactPersonsList",
          methodProperties: {
            CounterpartyRef: this.counterparty,
            ContactProperty: "Sender",
            Ref: this.counterpartyContacts,
            Limit: 200,
            Page: 1,
            getContactPersonAddress: 1,
            FindByString: ""
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.addresses = response.data.data[0].Addresses.WarehouseAddresses;

        // orderDialog('this.counterpartyContactsData', this.counterpartyContactsData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    checkCargo() {

    },
    checkClosing() {
      if (!this.isChanged) {
        this.confirmLeftDialogVisible = true
      }
      else {
        this.$emit('close')
      }
    },
    async addSender() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append('name', this.delivery.name);
          formData.append('uuid_ref', this.counterparty);
          formData.append('uuid_ref_sender', this.counterpartyContacts);
          formData.append('uuid_ref_addresses', this.counterpartyAddress);
          // formData.append('uuid_ref_city', this.delivery.city);
          // formData.append('uuid_ref_warehouse', this.delivery.warehouse);
          formData.append('PaymentMethod', this.delivery.PaymentMethod);
          formData.append('PayerType', this.delivery.PayerType);
          formData.append('AfterpaymentOnGoodsCost', this.delivery.AfterpaymentOnGoodsCost);
          formData.append('OwnershipFormDescription', this.delivery.OwnershipFormDescription);
          formData.append('is_monitor_returns', this.delivery.is_monitor_returns);
          formData.append('np_apikey', this.delivery.np_apikey);
          formData.append('phone', this.phone);
          if (this.delivery.CargoDescription.Ref) {
            formData.append('uuid_ref_cargo_description', this.delivery.CargoDescription.Ref);
            formData.append('cargo_decscription', this.delivery.CargoDescription.Description);
          }
          if (this.typeDialog === 'edit') {
            formData.append('uuid', this.delivery.uuid);
            await novaPochtaService.updateCounterpartyItem(formData);
          } else {
            formData.append('uuid_user', this.getUserProperty('uuid'));
            await novaPochtaService.addCounterpartyItem(formData);
          }
          const apikey = this.delivery.np_apikey
          this.resetForm();
          this.$emit('close');
          EventBus.$emit('reload-list-counterparty', apikey);
        } catch (e) {
          console.error(e);
        }
      }
    },
    async pasteForm() {
      this.counterparty = this.data.uuid_ref;
      this.copyCounterparty = this.data.uuid_ref;
      // await this.getDataCounterpartyContacts()
      this.counterpartyContacts = this.data.uuid_ref_sender;
      this.copyCounterpartyContacts = this.data.uuid_ref_sender;
      // await this.getAddressContactPerson()
      this.counterpartyAddress = this.data.uuid_ref_addresses;
      this.copyCounterpartyAddress = this.data.uuid_ref_addresses;

      this.delivery = {
        uuid: this.data.uuid,
        name: this.data.name,
        PaymentMethod: this.data.PaymentMethod,
        np_apikey: this.data.np_apikey,
        PayerType: this.data.PayerType,
        OwnershipFormDescription: this.data.OwnershipFormDescription,
        AfterpaymentOnGoodsCost: String(this.data.AfterpaymentOnGoodsCost).toLowerCase() === 'true',
        CargoDescription: {},
        is_monitor_returns: this.data.is_monitor_returns,
      }

      this.copyDelivery = {
        uuid: this.data.uuid,
        name: this.data.name,
        PaymentMethod: this.data.PaymentMethod,
        np_apikey: this.data.np_apikey,
        PayerType: this.data.PayerType,
        OwnershipFormDescription: this.data.OwnershipFormDescription,
        AfterpaymentOnGoodsCost: String(this.data.AfterpaymentOnGoodsCost).toLowerCase() === 'true',
        CargoDescription: {},
        is_monitor_returns: this.data.is_monitor_returns,
      }

      if (this.data.uuid_ref_cargo_description != '00000000-0000-0000-0000-000000000000') {
        this.delivery.CargoDescription = {
          Ref: this.data.uuid_ref_cargo_description,
          Description: this.data.cargo_decscription,
        }
        this.copyDelivery.CargoDescription = {
          Ref: this.data.uuid_ref_cargo_description,
          Description: this.data.cargo_decscription,
        }
        this.searchCargoDescQuery = this.data.cargo_decscription
      }

      // eslint-disable-next-line prefer-object-spread
      // this.copyDelivery = Object.assign({}, this.delivery)


      // await this.fetchWarehouseByRef(this.delivery.warehouse);
      this.updateNameAddress
    },
    resetForm() {
      this.$v.$reset();
      this.counterparty = null;
      this.counterpartyContacts = null;
      this.counterpartyAddress = null;
      this.delivery = {
        uuid: '',
        name: '',
        AfterpaymentOnGoodsCost:false,
        PayerType:"Sender",
        PaymentMethod:"Cash",
        OwnershipFormDescription:"",
        np_apikey: '',
        CargoDescription: {},
        is_monitor_returns: false,
      }
    },
  },
  computed: {
    visibility: {
      get() {
        if(Object.keys(this.data).length) {
          this.pasteForm()
        }
        return this.visible;
      },
      set() {
        this.resetForm();
        this.$emit('close');
      },
    },
    isChanged() {

      if (
        !_.isEqual(this.counterpartyAddress, this.copyCounterpartyAddress) ||
        !_.isEqual(this.counterpartyContacts, this.copyCounterpartyContacts) ||
        !_.isEqual(this.counterparty, this.copyCounterparty) ||
        !_.isEqual(this.delivery, this.copyDelivery)
      ) {
        return false;
      } else {
        return true;
      }
    },
    // cityErrors() {
    //   const errors = [];
    //   if (!this.$v.delivery.city.$dirty) {
    //     return errors;
    //   }
    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.delivery.city.required
    //   && errors.push(this.$t('form.errors.CityRequired'));
    //   return errors;
    // },
    npApiKeyErrors() {
      const errors = [];
      if (!this.$v.delivery.np_apikey.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.np_apikey.required
      && errors.push(this.$t('form.errors.NPKeyRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.delivery.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    // CargoDescriptionError() {
    //   const errors = [];
    //
    //   if (!this.$v.delivery.CargoDescription.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.delivery.CargoDescription.required &&
    //     errors.push(this.$t("form.errors.CargoDescriptionRequired"));
    //   return errors;
    // },
    counterpartyContactsErrors() {
      const errors = [];
      if (!this.$v.counterpartyContacts.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.counterpartyContacts.required
      && errors.push(this.$t('form.errors.CityRequired'));
      return errors;
    },
    counterpartyAddressErrors() {
      const errors = [];
      if (!this.$v.counterpartyAddress.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.counterpartyAddress.required
      && errors.push(this.$t('form.errors.AddressRequired'));
      return errors;
    },
    counterpartyErrors() {
      const errors = [];
      if (!this.$v.counterparty.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.counterparty.required
      && errors.push(this.$t('form.errors.CounterpartyRequired'));
      return errors;
    },
    // warehouseErrors() {
    //   const errors = [];
    //   if (!this.$v.delivery.warehouse.$dirty) {
    //     return errors;
    //   }
    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.delivery.warehouse.required
    //   && errors.push(this.$t('form.errors.DepartmentRequired'));
    //   return errors;
    // },
  },
  validations: {
    counterparty: { required },
    counterpartyContacts: { required },
    counterpartyAddress: { required },
    delivery: {
      name: { required },
      np_apikey: {required},
      // CargoDescription: {required}
    },
  },
};
</script>

<style scoped lang="scss">
.npDialog{
  &__header{
    &-title{
      padding: 32px 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #3D3D3D;
    }
    &-nav{
      display: flex;
      padding: 12px 24px;
    }
  }
}
</style>
